<template>
  <div>
    <div class="text-primary mb-s3">
      Trusted by
    </div>
      <flex-row class="gutter-x-s10 gutter-y-s3" align-v="center" wrap>
        <div v-for="name in items" :key="name">
          <img :src="getImagePath(name)" @error="setImgError(name)" v-if="!isImgError(name)" :alt="IMG_ALT[name]">
        </div>
      </flex-row>
  </div>

</template>

<script>
import { IMG_ALT } from '../constants/IMG_ALT.ts';
export default {
  name: 'LogoGroup',

  data: function () {
    return {
      items: ['bcg', 'societe_generale', 'wolters_kluwer', 'elsevier', 'cardano'],
      imgErrors: [],
      IMG_ALT
    }
  },
  methods: {
    getImagePath: function (name) {
      return `/images/logos/white/${name}.svg`
    },
    setImgError: function (name) {
      if (!this.imgErrors.find(img => img === name)) this.imgErrors.push(name)
    },
    isImgError: function (name) {
      return !!this.imgErrors.find(img => img === name);
    }
  }
}
</script>

<style scoped lang="scss">

</style>
