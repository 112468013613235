export const IMG_ALT = {
  bcg: 'BCG',
  societe_generale: 'Société Générale',
  elsevier: 'Elsevier',
  wolters_kluwer: 'Wolters Kluwer',
  reaxys: 'Reaxys',
  cardano: 'CARDANO',
  natixis: 'NATIXIS',
  algorand: 'Algorand',
  cosmos: 'COSMOS',
  commerzbank: 'Commerzbank',
  r3_c_rda: 'r3. c•rda',
  embase: 'Embase',
  hypha: 'HYPHA',
  wow_shop: 'WOW Shop',
  blues_chain: 'Blues Chain',
  strateos: 'Strateos',
  cbcd_tracker: 'CBCD Tracker',
  blueshift: 'Blueshift',
}
