<template>
  <section>
    <flex-row class="section-title pt-xs-s8 pb-xs-5" align-v="center" id="Expertise"> our expertise </flex-row>
    <div class="section-body">
      <div class="section-body-content py-xs-0">
        <div class="h2 text-center text-primary mb-7" v-if="!isMobile">Our expertise</div>
        <flex-row align-h="center" align-v="stretch" class="gutter-s5">
          <flex-col class="item w-xs-100" v-for="item in items" :key="item.image">
            <div>
              <img :src="`/images/${item.image}.svg`" />
            </div>
            <div class="h4 mt-s4">
              {{ item.caption }}
            </div>
          </flex-col>
        </flex-row>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Expertise",
  props: {
    isMobile: Boolean,
  },
  data: function () {
    return {
      items: [
        { image: "machine_learning", caption: "Machine Learning" },
        { image: "dev_ops", caption: "DevOps and DataOps" },
        { image: "blockchain", caption: "Blockchain" },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.item {
  width: 30%;
  margin-bottom: 2rem;
  > div {
    text-align: center;
  }
  img {
    max-width: 100%;
  }
  .mobile & {
    margin-bottom: 1rem;
  }
}
</style>
