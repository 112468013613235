<template>
  <flex-col align-v="between" class="project">
    <flex-col class="project-description w-64 w-xs-100">
      <div class="h2 mt-xs-7">
        {{ project.title }}
      </div>
      <div class="mt-s10 mt-xs-s5">
        {{ project.description }}
      </div>
      <div class="project-info mt-s8 mt-xs-s4">
        <flex-row class="gutter-s1" align-v="stretch" align-h="start">
          <div v-for="tool in project.tools" :key="tool" class="text-primary">#{{ tool }}</div>
        </flex-row>
        <flex-row  align-v="stretch" align-h="start"  v-if="project.devOpsTools" class="mt-s1">
          <div class="text-primary mr-s1 mt-s1">DevOps Tools: </div>
          <flex-row class="gutter-x-s1" align-v="stretch" align-h="start" wrap>
            <div v-for="tool in project.devOpsTools" :key="tool" class="text-primary mt-s1">#{{ tool }}</div>
          </flex-row>
        </flex-row>
      </div>
    </flex-col>

    <div class="project-footer">
      <div class="project-logos">
        <div v-for="logo in project.logos" :key="logo" class="project-logo-item">
          <img :src="getImgPath(logo)" :alt="IMG_ALT[logo]" />
        </div>
      </div>
      <div class="project-buttons">
        <button class="btn btn-primary mr-3 mr-xs-1" @click="openExternal(BOOK_A_CALL_LINK)">Book a call</button>
      </div>
    </div>
  </flex-col>
</template>

<script>
import { openExternal } from "@/utils/utils.ts";
import { BOOK_A_CALL_LINK } from "@/constants/EXTERNAL_LINKS.ts";
import { IMG_ALT } from '../constants/IMG_ALT.ts';
export default {
  name: "Project",
  props: {
    project: {
      name: String,
      description: String,
      tools: [String],
      devOpsTools: {
        type: [String],
        default: [],
      },
      logos: [String],
    },
  },
  data: function () {
    return {
      BOOK_A_CALL_LINK,
      IMG_ALT
    };
  },
  methods: {
    openExternal,
    getImgPath(logo) {
      return `/images/logos/color/${logo}.svg`;
    },
  },
};
</script>

<style scoped lang="scss">
.project {
  //min-height: 24rem;
  height: 100%;
}
.w-64 {
  width: 64%;
}
.project-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  .project-logos {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    .project-logo-item {
      margin-top: 24px;
      margin-right: 40px;
    }
    img {
      max-width: 100%;
    }
  }
  .mobile & {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 28px;
    flex-grow: 1;
    .project-logos {
      flex-wrap: wrap;
      margin-top: 0;
      .project-logo-item {
        transform: scale(0.75);
        transform-origin: left;
        margin-right: 0;
      }
    }
    .project-buttons {
      align-self: center;
      text-align: center;
      margin-top: 80px;
    }
  }
}
</style>
