<template>
  <flex-col tag="section" class="section-primary" id="Contacts">
    <flex-row class="section-title mt-5 mt-xs-3 pb-xs-5" align-v="center" noWrap> Contacts </flex-row>
    <div class="section-body">
      <form @submit.prevent="onSendRequest">
        <div class="section-body-content">
          <flex-row align-h="between" align-v="center">
            <div class="h2">Request a project</div>
            <flex-row align-h="between" align-v="center" class="flex-wrap-xs">
              <div class="label small corner-lb my-xs-5 w-xs-100">
                Ready to discuss a project? Let’s chat right now:
              </div>
              <flex-row align-h="center" class="ml-s2 w-xs-100" noWrap>
                <button class="btn-icon btn-white mr-3" @click="openExternal('https://t.me/firmshift_cryptodev')">
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.665 3.71682L2.93497 10.5538C1.72497 11.0398 1.73197 11.7148 2.71297 12.0158L7.26497 13.4358L17.797 6.79082C18.295 6.48782 18.75 6.65082 18.376 6.98282L9.84297 14.6838H9.84097L9.84297 14.6848L9.52897 19.3768C9.98897 19.3768 10.192 19.1658 10.45 18.9168L12.661 16.7668L17.26 20.1638C18.108 20.6308 18.717 20.3908 18.928 19.3788L21.947 5.15082C22.256 3.91182 21.474 3.35082 20.665 3.71682Z"
                      fill="#ED6060"
                    />
                  </svg>
                </button>
                <button
                  class="btn-icon btn-white mr-3"
                  @click="openExternal('https://www.linkedin.com/groups/13949610/')"
                >
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path
                        d="M6.201 21H1.84725V6.9795H6.201V21ZM4.0215 5.067C2.6295 5.067 1.5 3.9135 1.5 2.5215C1.5 1.85276 1.76566 1.2114 2.23853 0.73853C2.7114 0.265657 3.35276 0 4.0215 0C4.69024 0 5.3316 0.265657 5.80447 0.73853C6.27734 1.2114 6.543 1.85276 6.543 2.5215C6.543 3.9135 5.4135 5.067 4.0215 5.067ZM22.4955 21H18.1515V14.175C18.1515 12.5483 18.1185 10.4625 15.888 10.4625C13.6245 10.4625 13.2772 12.2295 13.2772 14.058V21H8.928V6.9795H13.1033V8.892H13.164C13.7452 7.79025 15.165 6.62775 17.283 6.62775C21.6893 6.62775 22.4993 9.5295 22.4993 13.2983V21H22.4955Z"
                        fill="#ED6060"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <button class="btn-icon btn-white" @click="openExternal('https://discord.gg/gGNYw4SBqx')">
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 6C20 6 17.707 4.205 15 4L14.756 4.4885C17.203 5.088 18.326 5.945 19.5 7C17.4765 5.967 15.48 5 12 5C8.52 5 6.5235 5.967 4.5 7C5.674 5.945 7.01 4.992 9.244 4.4885L9 4C6.16 4.2675 4 6 4 6C4 6 1.4395 9.713 1 17C3.58 19.9765 7.5 20 7.5 20L8.32 18.908C6.928 18.424 5.358 17.5605 4 16C5.619 17.225 8.0625 18.5 12 18.5C15.9375 18.5 18.381 17.225 20 16C18.6425 17.5605 17.0725 18.424 15.68 18.908L16.5 20C16.5 20 20.42 19.9765 23 17C22.5605 9.713 20 6 20 6ZM8.75 15C7.783 15 7 14.105 7 13C7 11.895 7.783 11 8.75 11C9.717 11 10.5 11.895 10.5 13C10.5 14.105 9.717 15 8.75 15ZM15.25 15C14.283 15 13.5 14.105 13.5 13C13.5 11.895 14.283 11 15.25 11C16.217 11 17 11.895 17 13C17 14.105 16.217 15 15.25 15Z"
                      fill="#ED6060"
                    />
                  </svg>
                </button>
              </flex-row>
              <div class="label small corner-lt mt-xs-5 w-xs-100" v-if="isMobile">
                Or fill in this form and we will contact you today
              </div>
            </flex-row>
          </flex-row>
          <flex-row align-h="between" align-v="start" class="mt-5">
            <div>
              <input placeholder="Name *" v-model="name" required />
              <input placeholder="Company / project name" v-model="company" />
              <input placeholder="Email *" v-model="mail" required />
              <input placeholder="Phone" v-model="phone" />
            </div>
            <div class="w-xs-100">
              <div class="mt-xs-3 w-xs-100">
                <textarea placeholder="Short description of the project" v-model="message"></textarea>
              </div>
              <flex-row align-v="start" align-h="between" class="mt-3 w-xs-100 flex-wrap-xs">
                <div class="label small corner-lt mr-s2" v-if="!isMobile">
                  Or fill in this form and we will contact you today
                </div>
                <button class="btn btn-white ml-auto">Send the request</button>
              </flex-row>
            </div>
          </flex-row>
        </div>
      </form>
    </div>
  </flex-col>
</template>

<script>
export default {
  name: "Contacts",
  props: {
    isMobile: Boolean,
  },
  data: function () {
    return {
      name: "",
      nameError: "",
      company: "",
      mail: "",
      mailError: "",
      phone: "",
      message: "",
      messageError: "",
      messageSent: false,
    };
  },
  methods: {
    openExternal(url) {
      window.open(url, "_blank");
    },
    onSendRequest() {
      this.messageSent = false;
      this.nameError = this.name.trim() === "" ? "Please provide us your name, so we know who’s contacting." : "";
      this.mailError =
        this.mail.trim() === "" ? "Please enter a (valid) e-mail address, so we can be in touch with you." : "";
      this.mailError ||=
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.mail.trim()
        )
          ? "Invalid email format"
          : "";
      this.messageError =
        this.message.trim() === ""
          ? "Seems you didn’t write any message. Mind telling what you’re contacting about?"
          : "";
      if (this.nameError || this.mailError || this.messageError) {
        return false;
      }

      const data = {
        email: this.mail.trim(),
        name: this.name.trim(),
        company: this.company.trim(),
        phone: this.phone.trim(),
        description: this.message.trim(),
      };

      fetch("https://goik2h5w26.execute-api.us-east-1.amazonaws.com/prod/fs-feedback-form", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(() => {
        this.name = this.mail =this.company = this.phone = this.message = "";
        this.messageSent = true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/form";
.section-body-content {
  @media (min-width: $screen-sm) {
    > .vf__flex-dir--row {
      > div:first-of-type {
        width: 30%;
      }
      > div:last-of-type {
        width: 66%;
      }
    }
  }
}
.label {
  border: 2px dashed rgba($white, 0.15);
  padding: 0.8rem 1.6rem;
  &.corner-lb {
    border-radius: 0.8rem 0.8rem 0.8rem 0;
  }
  &.corner-lt {
    border-radius: 0 0.8rem 0.8rem 0.8rem;
  }
}
.section-footer {
  @media (min-width: $screen-sm) {
    > div {
      width: 33%;
    }
    .h4 {
      margin-bottom: 1rem;
    }
  }
  @media (max-width: $screen-xs) {
    > div {
      width: 100%;
      + div {
        margin-top: 2rem;
      }
    }
    .h4 {
      margin-bottom: 0.6rem;
    }
  }
}
</style>
