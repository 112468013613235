<template>
  <div id="app">
    <MobileHeader v-if="isMobile" />
    <Welcome :is-mobile="isMobile" />
    <ContactBar v-if="!isMobile" />
    <Expertise :is-mobile="isMobile" />
    <Experience :is-mobile="isMobile" />
    <Partners :is-mobile="isMobile" />
    <Contacts :is-mobile="isMobile" />
    <Footer />
  </div>
</template>

<script>
import Welcome from "./sections/Welcome.vue";
import ContactBar from "./components/ContactBar";
import Expertise from "./sections/Expertise.vue";
import Partners from "./sections/Partners.vue";
import Contacts from "./sections/Contacts.vue";
import MobileHeader from "./components/MobileHeader";
import Experience from "./sections/Experience.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Footer,
    Experience,
    MobileHeader,
    Contacts,
    Partners,
    Expertise,
    ContactBar,
    Welcome,
  },

  data: function () {
    return {
      isMobile: window.innerWidth < 560,
    };
  },

  mounted() {
    this.track();
    if (this.isMobile) {
      document.getElementById("app").classList.add("mobile");
    }
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 560;
      const app = document.getElementById("app");
      if (this.isMobile) {
        app.classList.add("mobile");
      } else {
        app.classList.remove("mobile");
      }
    });
  },
  methods: {
    track() {
      this.$ga.page("/");
    },
  },
};
</script>

<style lang="scss">
#app {
  @media (max-width: $screen-xs) {
    padding-top: $header-mobile-height;
  }
}
</style>
