<template>
  <section id="Partners">
    <flex-row class="section-title pt-xs-s8 pb-xs-5" align-v="center"> Partners </flex-row>
    <div class="section-body">
      <div class="section-body-content py-xs-0 pr-xs-0 pl-xs-s2">
        <div class="h2 text-center text-primary mb-7" v-if="!isMobile">
          Partners and leading companies using Firmshift
        </div>
        <flex-row
          class="partner-logos gutter-x-s12 gutter-xs-x-s10 gutter-xs-y-s7 gutter-y-s12 mb-s10 mb-xs-s4"
          align-v="stretch"
          wrap
        >
          <div v-for="logo in logos" :key="logo" class="partner-logo">
            <img :src="`/images/logos/black/${logo}.svg`" :alt="IMG_ALT[logo]" />
          </div>
        </flex-row>
      </div>
    </div>
  </section>
</template>

<script>
import { IMG_ALT } from '../constants/IMG_ALT.ts';
export default {
  name: "Partners",
  props: {
    isMobile: Boolean,
  },
  data: function () {
    return {
      logos: [
        "bcg",
        "societe_generale",
        "elsevier",
        "wolters_kluwer",
        "reaxys",
        "cardano",
        "natixis",
        "algorand",
        "cosmos",
        "commerzbank",
        "r3_c_rda",
      ],
      IMG_ALT
    };
  },
};
</script>

<style scoped lang="scss">
.section-body-content {
  @media (min-width: $screen-sm) {
    justify-content: space-between;
  }
  @media (max-width: $screen-xs) {
    justify-content: flex-start;
  }
}

.partner-logos {
  justify-content: center;
  img {
    height: max(1.8rem, 32px);
  }
  .mobile & {
    justify-content: flex-start;
    img {
      max-height: 2rem;
    }
  }
}
</style>
