<template>
  <section>
    <div class="section-body">
      <flex-row tag="section" align-h="between" class="section-footer text-primary">
        <div class="mt-3">
          <div class="h4">E-mail</div>
          <div class="underline">hello@firmshift.com</div>
        </div>
        <div class="mt-3">
          <div class="h4">Telephone</div>
          <div>+31(0)20 261 8336</div>
        </div>
        <div class="mt-3">
          <div class="h4">Address</div>
          <div>
            Keizersgracht 62-64, 1015 CS<br />
            Amsterdam, the Netherlands
          </div>
        </div>
      </flex-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
section {
  box-shadow: 0 1px 0 #ffd2d3 inset, 0 0 0 #ffd2d3 inset;
}
</style>
