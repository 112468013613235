export const PROJECTS = [
  {
    title: 'BCG: Factory for fast prototyping of blockchain solutions',
    description: 'Our Factory solution enabled fast creation of blockchain networks, and allowed clients to learn how to design and deploy their own blockchain networks.',
    tools: ['ML', 'Graph database', 'Flat file database'],
    logos: ['bcg'],
  },
  {
    title: 'Societe Generale: KYC platform for European banks to streamline data management process',
    description: 'Our platform improved collaboration, decreased manual processing, enabling banks to onboard customers quicker than ever before and navigate the complex regulatory landscape, ensuring compliance and reducing risk.',
    tools: ['Corda R3', 'Java', 'React', 'Vue.js', 'AWS'],
    logos: ['societe_generale', 'natixis', 'commerzbank'],
  },
  {
    title: 'Wolters Kluwer: Cloud migration of all company data and applications',
    description: 'Our cloud migration solution provided greater scalability, flexibility, cost savings, improved performance, and enhanced security for our clients.',
    tools: ['AWS', 'Azure', 'Terraform'],
    devOpsTools: ['Chef', 'Puppet', 'Ansible', 'Docker', 'Kubernetes'],
    logos: ['wolters_kluwer'],
  },
  {
    title: 'Elsevier: ML solution for an expert-curated chemistry database',
    description: 'We developed an informatics solution that delivers chemistry facts the way chemists need them, and it has been widely adopted by the majority of pharmaceutical companies.',
    tools: ['ML', 'Graph database', 'Flat file database'],
    logos: ['elsevier', 'reaxys'],
  },
  {
    title: 'Portfolio-based AMM DEX and an asset management tool',
    description: 'We developed a high-loaded service with an implemented portfolio logic, reducing impermanent loss up to 10 times for an AMM model and giving clients a competitive edge in the crypto space.',
    tools: ['Java', 'Solidity', 'Blockchain', 'SmartContract', 'Plutus'],
    logos: ['blueshift'],
  },
  {
    title: 'Wolters Kluwer: DataOps solution for processing large amounts of data',
    description: 'With our DataOps solution, clients experienced faster processing, improved data quality, increased agility, enhanced collaboration, and greater efficiency.',
    tools: ['ML technology by AWS - Amazon SegMaker', 'Textract', 'Kendra', 'Personalize', 'Java'],
    logos: ['wolters_kluwer'],
  },
  {
    title: 'Web resource for CBDC info',
    description: 'Our CBDC Tracker provides a comprehensive view of the status of central banks digital currencies in different countries, along with historical perspective on how the process has evolved.',
    tools: ['Java', 'Vue.js', 'AWS'],
    logos: ['cbcd_tracker'],
  },
  {
    title: 'A cloud-based platform with automation technologies to advance drug discovery',
    description: 'Our cloud-based platform for lab automation and remote experimentation has increased efficiency and productivity, reduced costs, improved data quality, enhanced collaboration, and provided greater capacity for innovation for our clients in the life sciences industry.',
    tools: ['Java', 'PostgreSQL', 'Docker', 'K8s', 'AWS', 'RDKit'],
    logos: ['strateos'],
  },
  {
    title: 'L0 cross-chain solution',
    description: 'Our cross-chain protocol allowed for 15 times faster cross-chain transactions and connected all pairs from multiple chains for AMM DEXes, enhancing liquidity and enabling clients to easily conduct transactions across multiple chains.',
    tools: ['Java', 'Solidity', 'Blockchain', 'SmartContract', 'Cosmos SDK', 'Go'],
    logos: ['blues_chain'],
  },
  {
    title: 'A decentralized leveraged trading protocol',
    description: 'Our secure solution reduces the cost of transactions and ensures uninterrupted operation of the service. Traders can buy and sell tokens with up to 5X leverage on multiple chains, providing our clients with the tools they need.',
    tools: ['Java', 'Solidity', 'Blockchain', 'SmartContract'],
    logos: ['wow_swap'],
  },
  {
    title: 'Solution for a research platform and an expert-curated chemistry database',
    description: 'Our research platform, used by the majority of pharmaceutical companies, provides comprehensive, reliable chemical data, efficient research, better decision-making, collaboration, and knowledge sharing, and significant time savings for our clients.',
    tools: ['ML', 'Graph database', 'Flat file database'],
    logos: ['reaxys'],
  },
  {
    title: 'Simulation Process and Data Management System in research and engineering',
    description: 'Our ML solution efficiently managed simulation processes and data, reducing costs associated with simulation processes, improving knowledge management, and increasing process transparency for our clients.',
    tools: ['ML', 'AI', 'Java', 'Mathematics tools'],
    logos: ['hypha'],
  },
  {
    title: 'Elsevier: Resource for exploring deeply indexed content with advanced query capabilities',
    description: 'Our enhanced biomedical research tool uncovers valuable evidence that may not be discovered in other databases, helping researchers make breakthrough discoveries.',
    tools: ['AWS', 'Mark Logic', 'Java', 'Elasticsearch'],
    logos: ['elsevier', 'embase'],
  },
]
